<template>
  <div class="bidding">
    <Pane />

    <div class="container">
      <a-form :form="form" @submit="handleSubmit" :colon="false">
        <a-form-item>
          <a-input
            class="name"
            placeholder="请输入标段名称"
            @change="handleChange"
            v-model="name"
          />
        </a-form-item>

        <a-descriptions bordered :column="2">
          <a-descriptions-item label="开标地点" :span="2">
            <a-form-item>
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'bidAddress',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-descriptions-item>
          <a-descriptions-item label="招标方式">
            <a-form-item>
              <a-radio-group v-model="bidMethod">
                <a-radio
                  v-for="item in methodList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-radio
                >
              </a-radio-group>
            </a-form-item>
          </a-descriptions-item>
          <a-descriptions-item label="标底价(万元)">
            <a-form-item>
              <a-input-number
                :formatter="$inputNumberFormat"
                placeholder="请输入"
                v-decorator="[
                  'basePrice',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-descriptions-item>
          <a-descriptions-item label="所属地区">
            <a-form-item>
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'area',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-descriptions-item>
          <a-descriptions-item label="招标编号">
            <a-form-item>
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'code',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-descriptions-item>
          <a-descriptions-item label="招标业主">
            <a-form-item>
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'bidOwner',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-descriptions-item>

          <a-descriptions-item label="招标阶段">
            <a-form-item class="select">
              <a-select
                placeholder="请选择"
                v-decorator="[
                  'status',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-descriptions-item>

          <a-descriptions-item label="开标日期">
            <a-form-item class="date">
              <a-date-picker
                placeholder="请选择"
                v-decorator="[
                  'bidDate',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
                format="YYYY-MM-DD HH:mm:ss"
              />
            </a-form-item>
          </a-descriptions-item>

          <a-descriptions-item label="截标日期">
            <a-form-item class="date">
              <a-date-picker
                placeholder="请选择"
                v-decorator="[
                  'deadline',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
                format="YYYY-MM-DD HH:mm:ss"
              />
            </a-form-item>
          </a-descriptions-item>

          <a-descriptions-item label="是否EPC项目">
            <a-form-item>
              <a-radio-group
                v-decorator="[
                  'isEpc',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              >
                <a-radio :value="true">是</a-radio>
                <a-radio :value="false">否</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-descriptions-item>

          <a-descriptions-item label="项目编号">
            <a-form-item>
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'projectCode',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-descriptions-item>

          <a-descriptions-item label="项目名称" :span="2">
            <a-form-item>
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'projectName',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-descriptions-item>

          <a-descriptions-item label="联系人">
            <a-form-item>
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'contact',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-descriptions-item>
          <a-descriptions-item label="联系方式">
            <a-form-item>
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'contactMobile',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-descriptions-item>

          <a-descriptions-item label="供应商" :span="2">
            <a-form-item>
              <SupplierSeletor
                @change="(company) => (selectedCompany = company)"
                :msg="selectedCompany"
              >
                <div v-if="selectedCompany.length > 0" class="selected">
                  <span v-for="(item, index) in selectedCompany" :key="index">
                    {{ item.company
                    }}<span v-if="index !== selectedCompany.length - 1"
                      >、</span
                    >
                  </span>
                  <a-button style="margin-left: 5px">修改</a-button>
                </div>
                <a-button v-else>请选择供应商</a-button>
              </SupplierSeletor>
            </a-form-item>
          </a-descriptions-item>

          <a-descriptions-item label="附件" :span="2">
            <a-form-item class="file-container">
              <div class="link-list">
                <a-checkbox-group
                  :value="selectedFileList"
                  @change="onSelectFile"
                  v-if="fileList.length !== 0"
                >
                  <div class="link" v-for="item in fileList" :key="item.id">
                    <a-checkbox :value="item.id">
                      <a target="_blank" download :href="item.completePath">{{
                        item.name
                      }}</a>
                    </a-checkbox>
                  </div>
                </a-checkbox-group>

                <div style="color: #ccc" v-else>请上传附件</div>

                <div class="control-bar">
                  <FileUpload @uploaded="uploaded">
                    <div class="control">
                      <a-icon type="upload" />
                    </div>
                  </FileUpload>

                  <div class="control" @click="deleteFile">
                    <a-icon type="delete" />
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-descriptions-item>
        </a-descriptions>

        <div class="panel">
          <div class="row">
            <div class="label">采购项(标段)</div>
            <div class="value">
              <a-form-item>
                <a-input placeholder="请输入" v-decorator="['purchaseItem']" />
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="label">主要内容</div>
            <div class="value">
              <a-form-item>
                <a-input
                  placeholder="请输入"
                  v-decorator="['purchaseContent']"
                />
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="label">数量及单位</div>
            <div class="value">
              <a-form-item>
                <a-input
                  placeholder="请输入"
                  v-decorator="['purchaseNumber']"
                />
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="label">要求到货时间</div>
            <div class="value">
              <a-form-item class="date">
                <a-date-picker
                  placeholder="请选择"
                  v-decorator="['purchaseArrivalDate']"
                  style="width: 100%"
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </a-form-item>
            </div>
          </div>
        </div>

        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">取消</a-button>
            <a-button htmlType="submit" type="primary">修改</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import FileUpload from "@/components/file-upload";
import SupplierSeletor from "@/components/supplier-selector";

import { fetchDetail, edit } from "@/api/bidding";
import { mapGetters } from "vuex";

import moment from "moment";

export default {
  name: "editBidding",

  components: {
    FileUpload,
    SupplierSeletor,
  },

  data() {
    return {
      id: "",
      form: this.$form.createForm(this),
      name: "",
      bidMethod: "",

      fileList: [],
      selectedFileList: [],

      selectedCompany: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    methodList() {
      return this.findDataDict("bid_method");
    },
    booleanList() {
      return this.findDataDict("boolean");
    },
    statusList() {
      return this.findDataDict("bid_item_status").filter(
        (item) => item.value === "bidding" || item.value === "finished"
      );
    },
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};

    if (!id) {
      return;
    }
    this.id = id;

    this.getDetail();
  },

  methods: {
    getDetail() {
      fetchDetail({ id: this.id }).then((res) => {
        console.log(res);

        this.name = res.name;
        this.bidMethod = res.bidMethod;
        this.purchaseItem = res.purchaseItem;
        this.selectedCompany = JSON.parse(res.invitedCompany);

        this.fileList = JSON.parse(res.bidFiles) || [];

        this.form.setFieldsValue({
          area: res.area,
          basePrice: res.basePrice,
          bidAddress: res.bidAddress,
          bidDate: moment(res.bidDate),
          bidOwner: res.bidOwner,
          code: res.code,
          contact: res.contact,
          contactMobile: res.contactMobile,
          deadline: moment(res.deadline),
          projectCode: res.projectCode,
          projectName: res.projectName,
          isEpc: res.isEpc,
          purchaseArrivalDate: res.purchaseArrivalDate
            ? moment(res.purchaseArrivalDate)
            : null,
          purchaseContent: res.purchaseContent,
          purchaseNumber: res.purchaseNumber,
          purchaseItem: res.purchaseItem,
          status: res.status,
        });
      });
    },

    handleChange() {
      // 添加采购项
      this.form.setFieldsValue({
        purchaseItem: this.name,
      });
    },

    handleSubmit(e) {
      e.preventDefault();

      // 校验是否填入标段名称
      if (!this.name) {
        this.$message.error("请填入标段名称");
        return;
      }

      // 校验若为邀请招标，是否填入供应商
      if (this.bidMethod === "invite" && !this.selectedCompany.length) {
        this.$message.error("请填入供应商");
        return;
      }

      this.form.validateFields((err, values) => {
        console.log(values);
        console.log(this.id);
        if (!err) {
          edit({
            id: this.id,
            ...values,
            bidDate: values.bidDate.format("YYYY-MM-DD HH:mm:ss"),
            deadline: values.deadline.format("YYYY-MM-DD HH:mm:ss"),
            bidFiles: JSON.stringify(this.fileList),
            name: this.name,
            bidMethod: this.bidMethod,

            purchaseArrivalDate: values.purchaseArrivalDate
              ? values.purchaseArrivalDate.format("YYYY-MM-DD HH:mm:ss")
              : undefined,
            invitedCompany: JSON.stringify(this.selectedCompany),
          }).then(() => {});
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },
  },
};
</script>

<style lang="less" scoped>
input {
  outline: none;
  border: none;
}
input:focus {
  box-shadow: none;
  width: 100%;
}

.container {
  background-color: #fff;
  padding: 50px 160px;
  .name {
    border: 1px solid #ccc;
    text-align: center;
    margin-bottom: 67px;
    height: 40px;
  }
  .link-list {
    border: none;
    padding-left: 0px;
  }
}

.panel {
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  .row {
    display: flex;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
    line-height: 40px;
    .label {
      width: 20%;
      background-color: #fafafa;
      border-right: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;
    }
    .value {
      width: 80%;
      background-color: #fff;
      border-right: 1px solid #e8e8e8;
      padding-left: 20px;
    }
  }
}

.selected {
  line-height: 20px;
  margin: 5px 0;
  span {
    font-weight: 800;
  }
}

.center {
  margin-top: 80px;
}
</style>

<style lang="less">
.bidding {
  .ant-form-item {
    border: none;
    margin-bottom: 0;
  }
  .ant-input {
    border: none;
    box-shadow: none;
    padding-left: 0;
    :focus {
      border-color: #fff;
    }
  }

  .ant-form-item-control-wrapper {
    width: 100%;
  }
  .ant-descriptions-item-content {
    padding: 0;
    & > div {
      margin: 0 20px;
    }
    .select {
      margin: 0;
      .ant-select-selection--single {
        border: none;
        box-shadow: none;
        .ant-select-selection__rendered {
          margin: 0 20px;
          .ant-select-selection-selected-value {
            color: #1890ff;
          }
        }
      }
    }

    .date {
      margin: 0;
    }

    .ant-radio-wrapper-checked {
      span {
        color: #1890ff;
      }
    }

    .ant-calendar-picker {
      padding-left: 20px;
    }

    .ant-input-number {
      border: none;
      width: 100%;
      .ant-input-number-input {
        padding-left: 0;
      }
    }
  }

  .ant-descriptions-bordered .ant-descriptions-item-label {
    padding: 11px 28px;
    text-align: center;
  }
}
</style>
